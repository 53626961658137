import { T } from '@tolgee/react';
import {
  seriesExchangeEncode,
  SeriesExchangeExtractor,
} from '@zakodium/profid-shared';
import { useMemo, useState } from 'react';
import { match } from 'ts-pattern';

import ErrorBoundary from '../../../components/ErrorBoundary';
import { PfdDropzone } from '../../../components/PfdDropzone';
import { ExperimentalAlert } from '../../../components/experimental-features/ExperimentalWarning';
import FormattedErrorAlert from '../../../components/translation/FormattedErrorAlert';
import {
  FormattedStep,
  FormattedStepper,
} from '../../../components/translation/FormattedStepper';
import { useSqliteDatabase } from '../utils';

import { ConflictManager } from './import_serie_page/conflict_manager';
import { ImportProgress } from './import_serie_page/import_progress';
import { PreviewAlert } from './import_serie_page/preview_alert';
import { PreviewSerie } from './import_serie_page/preview_serie';

import { useImportSerieMutation } from '#gql';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert } from '#utils/assert';
import { JSError } from '#utils/error/api';

const STEPS: FormattedStep[] = [
  {
    id: 'pfd-upload',
    label: 'series_exchange.import.steps.file',
  },
  {
    id: 'series-preview',
    label: 'series_exchange.import.steps.series',
  },
  {
    id: 'series-import',
    label: 'series_exchange.import.steps.import',
  },
];
type PossibleStep = 0 | 1 | 2;
const STEP_PFD_UPLOAD = 0;
const STEP_SERIES_PREVIEW = 1;
const STEP_SERIES_IMPORT = 2;

export function ImportSeriePage() {
  const [buffer, setBuffer] = useState<Uint8Array | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [step, setStep] = useState<PossibleStep>(STEP_PFD_UPLOAD);
  const [jobId, setJobId] = useState<string | null>(null);

  function onReset() {
    setBuffer(null);
    setPassword(null);
    setStep(STEP_PFD_UPLOAD);
    setJobId(null);
  }

  function goToPreviewStep(buffer: Uint8Array, password: string) {
    setBuffer(buffer);
    setPassword(password);
    setStep(STEP_SERIES_PREVIEW);
  }

  function goToImportStep(jobId: string) {
    setStep(STEP_SERIES_IMPORT);
    setJobId(jobId);
  }

  function returnToPFDUploadStep() {
    setStep(STEP_PFD_UPLOAD);
  }

  return (
    <PageLayout
      title={<T keyName="page.series.import" />}
      navigation={
        <PageLayoutNavigation to="/series">
          <T keyName="nav.series.list" />
        </PageLayoutNavigation>
      }
    >
      <ErrorBoundary onReset={onReset}>
        <ExperimentalAlert />

        <FormattedStepper steps={STEPS} current={step} />

        {match(step)
          .with(STEP_PFD_UPLOAD, () => (
            <PfdDropzone onSubmitPreview={goToPreviewStep} />
          ))
          .with(STEP_SERIES_PREVIEW, () => {
            assert(buffer);
            assert(password);
            return (
              <ImportSeriePreview
                buffer={buffer}
                password={password}
                onImport={goToImportStep}
                onPreviousStep={returnToPFDUploadStep}
              />
            );
          })
          .with(STEP_SERIES_IMPORT, () => {
            assert(jobId);
            return <ImportProgress jobId={jobId} />;
          })
          .exhaustive()}
      </ErrorBoundary>
    </PageLayout>
  );
}

interface ImportSeriePreviewProps {
  buffer: Uint8Array;
  password: string;
  onImport: (jobId: string) => void;
  onPreviousStep: () => void;
}

function ImportSeriePreview(props: ImportSeriePreviewProps) {
  const { buffer, password } = props;

  const database = useSqliteDatabase(buffer, sqliteErrorWrapper);
  const extractor = useMemo(
    () => database && new SeriesExchangeExtractor(database),
    [database],
  );

  const [importSerie] = useImportSerieMutation();
  const [errors, setErrors] = useState<
    ReadonlyArray<Error & { reactKey: string }>
  >([]);

  if (!extractor) return null;

  /*
   * set newName if defined by ConflictManager
   * serialize database
   * encode with password
   * run the import mutation
   * transmit jobId to next step
   *
   * mutate possible errors to put a reactKey
   */
  function onImport(newName?: string) {
    async function runAsync() {
      assert(database);

      if (newName) {
        database
          .prepare('UPDATE series SET name = $name')
          .run({ $name: newName });
      }

      const encodedBuffer = await seriesExchangeEncode(
        database.serialize(),
        password,
      );

      const { data, errors } = await importSerie({
        variables: {
          input: {
            password: props.password,
            attachment: new File([encodedBuffer], 'import.pfd', {
              type: 'application/octet-stream',
              lastModified: Date.now(),
            }),
          },
        },
      });

      // eslint-disable-next-line @typescript-eslint/only-throw-error
      if (errors?.length) throw errors;
      assert(data);

      props.onImport(data.importSerie);
    }

    runAsync().catch((error) => {
      if (Array.isArray(error)) {
        for (const e of error) {
          e.reactKey = crypto.randomUUID();
        }
        setErrors(error);
      }

      error.reactKey = crypto.randomUUID();
      setErrors([error]);
    });
  }

  return (
    <section className="space-y-5">
      <ConflictManager
        extractor={extractor}
        onImport={onImport}
        onPreviousStep={props.onPreviousStep}
      >
        <PreviewSerie extractor={extractor} />
        <PreviewAlert extractor={extractor} />
      </ConflictManager>

      <div className="space-y-2">
        {errors.map((error) => (
          <FormattedErrorAlert key={error.reactKey} error={error} />
        ))}
      </div>
    </section>
  );
}

function sqliteErrorWrapper(error: Error) {
  return new JSError(
    'Buffer is not a valid sqlite database',
    'series_exchange.import.INCORRECT_FILE',
    error,
  );
}
