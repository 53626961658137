import { T, useTranslate } from '@tolgee/react';
import {
  formatSeriesSeqId,
  SeriesExchangeExtractor,
} from '@zakodium/profid-shared';
import { useEffect, useMemo } from 'react';
import { FormattedDate } from 'react-intl';

import {
  SerieCountry,
  SerieFraudType,
  SerieManagers,
  SerieProfile,
  SerieType,
} from '../../../../components/ViewComponents';
import { ImportMetadataCard } from '../../../../components/serie/ImportMetadataCard';

import { useSerieInstanceUrlQuery } from '#gql';
import {
  SeriesAttachment,
  SeriesAttachmentsCard,
  SeriesAttachmentsContainer,
  SeriesImageOverlay,
  SeriesImagesCard,
  SeriesImagesContainer,
} from '#series';
import { ZoomImage } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { PartialOverlay } from '#ui/overlay';
import { assert } from '#utils/assert';

interface SeriePreviewProps {
  extractor: SeriesExchangeExtractor;
}

export function PreviewSerie(props: SeriePreviewProps) {
  const { t } = useTranslate();

  const { extractor } = props;
  const serie = extractor.getSeries().at(0);
  assert(serie);

  const metadata = extractor.getMetadata();
  const application = extractor.getApplication();

  const seqIdCard = formatSeriesSeqId({
    code: application.code,
    seqYear: serie.seqYear,
    seqNumber: serie.seqNumber,
  });

  const { data } = useSerieInstanceUrlQuery({
    variables: {
      code: application.code,
      seqNumber: serie.seqNumber,
      seqYear: serie.seqYear,
    },
  });

  const { attachments, images } = useMemo(
    () => extractFiles(extractor, serie.id),
    [extractor, serie.id],
  );

  useEffect(() => {
    return () => {
      for (const attachment of attachments) {
        URL.revokeObjectURL(attachment.url);
      }
      for (const image of images) {
        URL.revokeObjectURL(image.url);
      }
    };
  }, [attachments, images]);

  return (
    <>
      <h1 className="text-xl">
        {t({
          key: 'series_exchange.preview.serie.name',
          params: {
            seqId: (
              <span key="seqId" className="font-semibold">
                {seqIdCard}
              </span>
            ),
            name: (
              <span key="name" className="font-semibold text-neutral-500">
                {serie.name}
              </span>
            ),
            exportedAt: (
              <time
                key="exportedAt"
                dateTime={metadata.exportedAt.toISOString()}
                className="font-semibold"
              >
                <FormattedDate value={metadata.exportedAt} />
              </time>
            ),
            from: (
              <span key="from" className="font-semibold">
                {application.title}
              </span>
            ),
          },
        })}
      </h1>
      <details open className="space-y-2">
        <summary>
          <h3 className="inline cursor-pointer font-semibold">
            <T keyName="series_exchange.preview.serie.title" />
          </h3>
        </summary>

        <div className="flex flex-row gap-5">
          <div className="flex flex-1 flex-col gap-5">
            <CardLayout title="page.series.view.general_information">
              <SerieType docType={serie.docType} />
              <SerieFraudType fraudType={serie.docFraudType} />
              <SerieCountry country={serie.docCountry} />
              <SerieManagers managers={[]} />
            </CardLayout>

            <CardLayout title="series.field.contextual_profile">
              <SerieProfile profile={serie.contextualProfile} />
            </CardLayout>

            <CardLayout title="series.field.material_profile">
              <SerieProfile profile={serie.materialProfile} />
            </CardLayout>

            <SeriesAttachmentsCard>
              <SeriesAttachmentsContainer
                attachments={attachments}
                renderAttachments={(attachment) => (
                  <SeriesAttachment
                    attachment={attachment}
                    anchorProps={{ download: attachment.filename }}
                  />
                )}
              />
            </SeriesAttachmentsCard>

            <SeriesImagesCard>
              <SeriesImagesContainer
                images={images}
                renderImage={(image) => (
                  <SeriesImageOverlay
                    Overlay={PartialOverlay}
                    label={image.filename}
                  >
                    <ZoomImage alt={image.filename} src={image.url} />
                  </SeriesImageOverlay>
                )}
              />
            </SeriesImagesCard>
          </div>

          <div className="flex w-1/3 flex-1 flex-col gap-5 self-start">
            <ImportMetadataCard
              metadata={{
                title: application.title,
                name: serie.name,
                exportedAt: metadata.exportedAt,
                originUrl: data?.serieInstanceUrl ?? null,
                originSeqId: seqIdCard,
              }}
              importFirstSeizureDate={mapDocSeizureDate(
                serie.docFirstSeizureDate,
              )}
              importLastSeizureDate={mapDocSeizureDate(
                serie.docLastSeizureDate,
              )}
              importDocumentCount={mapDocCount(serie.docCount)}
            />
          </div>
        </div>
      </details>
    </>
  );
}

function mapDocSeizureDate(value: Record<string, Date | null>): Array<{
  code: string;
  value: Date | null;
}> {
  return Object.entries(value).map(([code, value]) => ({
    code,
    value,
  }));
}

function mapDocCount(value: Record<string, number>): Array<{
  code: string;
  value: number;
}> {
  return Object.entries(value).map(([code, value]) => ({ code, value }));
}

function extractFiles(extractor: SeriesExchangeExtractor, seriesId: string) {
  const attachments = extractor
    .getSeriesAttachments(seriesId)
    .map(({ id, filename, idSqlar }) => {
      const buffer = extractor.getSqlarBlob(idSqlar);
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const size = extractor.getSqlarSize(idSqlar);
      const url = URL.createObjectURL(blob);

      return {
        id,
        filename,
        url,
        size,
      };
    });

  const images = extractor
    .getSeriesImages(seriesId)
    .map(({ id, filename, idSqlar }) => {
      const buffer = extractor.getSqlarBlob(idSqlar);
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);

      return {
        id,
        filename,
        url,
      };
    });

  return { images, attachments };
}
