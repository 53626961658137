import { useLoginState } from '../providers/LoginProvider.context';

import { Announcement, useAnnouncement } from './useAnnouncement';

import { Button, LightBanner } from '#tailwind_ui';

export default function AnnouncementBanner() {
  const loginState = useLoginState();
  if (loginState.status !== 'authenticated') {
    return null;
  }
  return <AnnouncementBannerAuthenticated />;
}

function AnnouncementBannerAuthenticated() {
  const [announcements, dismiss] = useAnnouncement();

  if (announcements.length === 0) {
    return null;
  }

  return (
    <div className="space-y-1 print:hidden">
      {announcements.map((announcement) => (
        <LightBanner
          key={announcement.id}
          onDismiss={() => dismiss(announcement.id)}
          description={announcement.message}
          renderButton={() => renderButton(announcement)}
        />
      ))}
    </div>
  );
}

function renderButton(announcement: Announcement) {
  if (!('link' in announcement)) {
    return null;
  }
  return (
    <Button variant="secondary" as="a" href={announcement.link} target="_blank">
      {announcement.linkText}
    </Button>
  );
}
