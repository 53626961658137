import { T, useTranslate } from '@tolgee/react';
import { ReactNode, useMemo } from 'react';
import { FormattedDate } from 'react-intl';

import { GqlSerieQuery } from '#gql';
import { AccordionCard, CardLayout } from '#ui/card_layout';
import { HighlightedLink } from '#ui/link';

interface ImportMetadataCardProps {
  metadata: NonNullable<GqlSerieQuery['serie']['importMetadata']>;
  importFirstSeizureDate: GqlSerieQuery['serie']['importFirstSeizureDate'];
  importLastSeizureDate: GqlSerieQuery['serie']['importLastSeizureDate'];
  importDocumentCount: GqlSerieQuery['serie']['importDocumentCount'];
}

export function ImportMetadataCard(props: ImportMetadataCardProps) {
  const {
    metadata,
    importFirstSeizureDate,
    importLastSeizureDate,
    importDocumentCount,
  } = props;

  const { t } = useTranslate();
  const { originSeqId, originUrl, title, exportedAt, name } = metadata;

  const seizureDates = useMemo(
    () => zipByCode(importFirstSeizureDate, importLastSeizureDate),
    [importFirstSeizureDate, importLastSeizureDate],
  );

  return (
    <CardLayout
      title="series.field.import"
      bodyClassName="flex flex-col gap-3"
      footerClassName="!border-t-0 text-right"
      footer={
        <>
          {originUrl ? (
            <HighlightedLink to={originUrl} target="_blank">
              <T
                keyName="page.series.view.import_metadata.imported_from"
                params={{ seqId: originSeqId }}
              />
            </HighlightedLink>
          ) : (
            <span>{originSeqId}</span>
          )}
        </>
      }
    >
      <ul className="flex list-disc flex-col">
        <Metadata
          label="page.series.view.import_metadata.exported_at"
          value={<FormattedDate value={exportedAt} />}
        />
        <Metadata label="page.series.view.import_metadata.name" value={name} />
        <Metadata
          label="page.series.view.import_metadata.meta_title"
          value={title}
        />
      </ul>

      <div>
        <AccordionCard title="series.field.import.seizure_date" defaultOpen>
          <ul>
            {seizureDates.map(({ code, from, to }) => (
              <li key={`${code}-seizure_date`}>
                {!from || !to ? (
                  <T
                    keyName="page.series.view.import_metadata.seizure_date.empty"
                    params={{ code }}
                  />
                ) : (
                  <>
                    {t('page.series.view.import_metadata.seizure_date', {
                      code,
                      from: <FormattedDate value={from} />,
                      to: <FormattedDate value={to} />,
                    })}
                  </>
                )}
              </li>
            ))}
          </ul>
        </AccordionCard>

        <AccordionCard title="series.field.import.document_counts" defaultOpen>
          <ul>
            {importDocumentCount.map(({ code, value }) => (
              <li key={`${code}-document_count`}>
                <T
                  keyName="page.series.view.import_metadata.document_count"
                  params={{ code, value }}
                />
              </li>
            ))}
          </ul>
        </AccordionCard>
      </div>
    </CardLayout>
  );
}

function zipByCode(
  first: GqlSerieQuery['serie']['importFirstSeizureDate'],
  last: GqlSerieQuery['serie']['importLastSeizureDate'],
) {
  return first.map((date) => {
    const lastDate = last.find((d) => d.code === date.code);

    if (!lastDate) {
      throw new Error(`key ${date.code} not found`);
    }

    return { code: date.code, from: date.value, to: lastDate.value };
  });
}

interface MetadataProps {
  label: TranslationKey;
  value: ReactNode;
}

function Metadata(props: MetadataProps) {
  return (
    <div>
      <span className="underline">
        <T keyName={props.label} />
      </span>
      : {props.value}
    </div>
  );
}
